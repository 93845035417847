import React, {
  useEffect,
} from 'react';

import { Container} from "reactstrap";
import { Link } from "react-router-dom";
import { jarallax, jarallaxVideo } from 'jarallax';
import jobs from '../jobs.js';

const JobList = (props) => {
  const { openings } = props;

  if( openings.length == 0 ) {
      return (
        <tbody>
          <tr>
            <td colSpan="3">
              Check back for an open position in this department soon!
            </td>
          </tr>
        </tbody>
      )
  }

  return (
    <tbody>
    {openings.map((job, idx) => (
      <tr key={idx}>
        <td>
          <Link to={`/careers/${props.cat}/${job.id}`} className="text-reset text-decoration-none">
            <p className="mb-1">
              {job.role}
            </p>
            <p className="fs-sm text-muted mb-0">
              {job.description}
            </p>
          </Link>
        </td>
        <td>
          <a href="career-single.html" className="text-reset text-decoration-none">
            <p className="fs-sm mb-0">
              {job.team}
            </p>
          </a>
        </td>
        <td>
          <a href="career-single.html" className="text-reset text-decoration-none">
            <p className="fs-sm mb-0">
              {job.location}
            </p>
          </a>
        </td>
      </tr>
    ))}
    </tbody>
  )
}

const Careers = () => {

  useEffect(() => {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2
    });
  }, []);

  return (
    <div  className="bg-white">
    <section data-jarallax="" className="jarallax" data-speed=".8" className="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover jarallax" style={{ backgroundImage: 'none', zIndex: 0 }} data-jarallax-original-styles="background: url('/assets/img/covers/cover-13.jpg') no-repeat center center fixed; background-size: cover" >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 text-center">

                <h1 className="display-2 text-white">
                  Want to work with us?
                </h1>


                <p className="lead text-white-75 mb-6">
                  We're always looking to hire talented folks to join our ever-growing team of designers, engineers, and support staff.
                </p>


                <a href="#" className="btn btn-primary lift">
                  View open positions <i className="fe fe-arrow-right ms-3"></i>
                </a>

              </div>
            </div>
          </div>
        <div  style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', zIndex: -100}}><div style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', background: 'url(/assets/img/covers/cover-13.jpg) no-repeat center center fixed', position: 'absolute', top: '0px', left: '0px', width: '1050px', height: '678.4px', overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', willChange: 'transform, opacity', marginTop: '104.8px', transform: 'translate3d(0px, -122.2px, 0px)' }}></div></div>
      </section>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x text-light">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"></path></svg>
        </div>
      </div>

      <section className="pt-8 pt-md-11">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-md-6">

            <span className="badge bg-primary-desat-soft rounded-pill mb-3">
              <span className="h6 text-uppercase">
                Values
              </span>
            </span>

            <h2>
              We care about everyone, <br />
              <span className="text-primary-desat">but employees are first</span>.
            </h2>

            <p className="fs-lg text-muted mb-6 mb-md-0">
              No matter your job title or department, if you're a member of our team you are our top priority. We can deeply about everyone who works with us.
            </p>

          </div>
          <div className="col-12 col-md-6 col-xl-5">

            <div className="row gx-0">
              <div className="col-4">

                <div className="w-150 mt-9 p-1 bg-white shadow-lg">
                  <img src="/assets/img/photos/photo-13.jpg" className="img-fluid" alt="..." />
                </div>

              </div>
              <div className="col-4">


                <div className="w-150 p-1 bg-white shadow-lg">
                  <img src="/assets/img/photos/photo-14.jpg" className="img-fluid" alt="..." />
                </div>

              </div>
              <div className="col-4 position-relative">


                <div className="w-150 mt-11 float-end p-1 bg-white shadow-lg">
                  <img src="/assets/img/photos/photo-15.jpg" className="img-fluid" alt="..." />
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section className="py-8 py-md-11">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-md-5 order-md-2">

            <span className="badge bg-success-soft rounded-pill mb-3">
              <span className="h6 text-uppercase">
                Benefits
              </span>
            </span>


            <h2>
            </h2>


            <p className="fs-lg text-muted mb-6 mb-md-0">
              We put our money where our mouthes are. Once you're part of our team, we're going to take the best possible care of you with tons of benefits and perks unavailable anywhere else.
            </p>

          </div>
          <div className="col-12 col-md-6 order-md-1">


            <div className="card card-border border-success shadow-lg">
              <div className="card-body">


                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center">


                    <div className="me-auto">


                      <p className="fw-bold mb-1">
                        Comprehensive benefits
                      </p>


                      <p className="fs-sm text-muted mb-0">
                        Health, dental, vision, 401k, and more.
                      </p>

                    </div>


                    <div className="badge badge-rounded-circle bg-success-soft ms-4">
                      <i className="fe fe-check"></i>
                    </div>

                  </div>
                  <div className="list-group-item d-flex align-items-center">


                    <div className="me-auto">


                      <p className="fw-bold mb-1">
                        6-Weeks of Paid Time Off
                      </p>


                      <p className="fs-sm text-muted mb-0">
                        Vacation on your own terms.
                      </p>

                    </div>


                    <div className="badge badge-rounded-circle bg-success-soft ms-4">
                      <i className="fe fe-check"></i>
                    </div>

                  </div>

                  <div className="list-group-item d-flex align-items-center">


                    <div className="me-auto">


                      <p className="fw-bold mb-1">
                        8-Week mandatory sabatical after 4 years of continuous service
                      </p>


                      <p className="fs-sm text-muted mb-0">
                        You have pretty much been keeping this place running for the past 4 years. Take time to disconnect and rediscover yourself.
                      </p>

                    </div>


                    <div className="badge badge-rounded-circle bg-success-soft ms-4">
                      <i className="fe fe-check"></i>
                    </div>

                  </div>

                  <div className="list-group-item d-flex align-items-center">


                    <div className="me-auto">


                      <p className="fw-bold mb-1">
                        Cutting edge hardware
                      </p>


                      <p className="fs-sm text-muted mb-0">
                        We provide brand new computers and phones.
                      </p>

                    </div>


                    <div className="badge badge-rounded-circle bg-success-soft ms-4">
                      <i className="fe fe-check"></i>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section data-jarallax="" data-speed=".8" className="py-14 py-lg-16 jarallax" style={{ backgroundImage: 'none', position: 'relative', zIndex: 0 }} data-jarallax-original-styles="background-image: url('assets/img/covers/cover-4.jpg'); background-size: cover">

      <div className="shape shape-top shape-fluid-x text-light">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor"></path></svg>      </div>

      <div className="shape shape-bottom shape-fluid-x text-light">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M720 125L2160 0h720v250H0V125h720z" fill="currentColor"></path></svg>
      </div>

      <div id="jarallax-container-1" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', zIndex: '-100' }}>
        <div style={{ backgroundSize: 'cover', background: 'url(/assets/img/covers/cover-4.jpg) no-repeat center center fixed', position: 'absolute', top:' 0px', left: '0px', width: '100%', height: '817.6px', overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', willChange: 'transform, opacity', marginTop: '35.2px', transform: 'translate3d(0px, -207.497px, 0px)' }}>
        </div>
      </div>
    </section>

      <section className="pt-6 pt-md-8">
      <div className="container pb-8 pb-md-11 border-bottom border-gray-300">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">


            <span className="badge rounded-pill bg-primary-desat-soft mb-3">
              <span className="h6 text-uppercase">Applying</span>
            </span>


            <h2>
              Let’s find you an <span className="text-primary">open position</span>.
            </h2>


            <p className="fs-lg text-gray-700 mb-7 mb-md-9">
              Find the right job for you no matter what it is that you do.
            </p>

          </div>
        </div>


        {jobs.map((team, idx) => (
          <TeamListing team={team} cat={idx} key={idx} />
        ))}

        <p className="fs-sm text-center text-muted mb-0">
          Don’t see the job you want? Email us at <code>careers [at] angrystatic [dot] com</code>.
        </p>
      </div>
    </section>

    <section className="pt-8 pt-md-11">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">


            <h2>
              Remember, our employees are our biggest fans.
            </h2>


            <p className="fs-lg text-muted mb-7 mb-md-9">
              No matter your job title or department, if you're a member of our team you are our top priority. We care deeply about everyone who works with us.
            </p>

          </div>
        </div>
        <div className="card card-row shadow-light-lg">
          <div className="row gx-0">
            <a className="col-12 col-md-6 bg-cover card-img-start" style={{ backgroundImage: 'url(/assets/img/photos/photo-1.jpg)' }} href="#!">

              <img src="/assets/img/photos/photo-1.jpg" alt="..." className="img-fluid d-md-none invisible" />

              <div className="shape shape-end shape-fluid-y text-white d-none d-md-block">
                <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M116 0H51v172C76 384 0 517 0 517v173h116V0z" fill="currentColor"></path></svg>
              </div>

            </a>
            <div className="col-12 col-md-6 order-md-1">


              <div className="card-body">
                <blockquote className="blockquote mb-0 text-center">


                  <p className="mb-5 mb-md-7">
                    “AngryStatic gave me the start to my career.”
                  </p>


                  <footer className="blockquote-footer">
                    <span className="h6 text-uppercase">Chaney Davis</span>
                  </footer>

                </blockquote>
              </div>

            </div>

          </div>
        </div>

      </div>
    </section>

    <div className="position-relative mt-n11" style={{paddingTop: 60}}>
      <div className="shape shape-bottom shape-fluid-x text-dark">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"></path></svg>      </div>
    </div>

    </div>
  )
}

const TeamListing = (props) => {
  let team = props.team;
  return (
    <>
    <div className="row align-items-center mb-5">
      <div className="col">

        <h4 className="fw-bold mb-1">
          {team.name}
        </h4>

        <p className="fs-sm text-muted mb-0">
          {team.description}
        </p>

      </div>
      <div className="col-auto">

        <span className="badge rounded-pill bg-success-soft">
          <span className="h6 text-uppercase">{team.jobs.length} openings</span>
        </span>

      </div>
    </div>
    <div className="row">
      <div className="col-12">

        <div className="table-responsive mb-7 mb-md-9">
          <table className="table table-align-middle">
            <thead>
              <tr>
                <th>
                  <span className="h6 text-uppercase fw-bold">
                    Role
                  </span>
                </th>
                <th>
                  <span className="h6 text-uppercase fw-bold">
                    Team
                  </span>
                </th>
                <th>
                  <span className="h6 text-uppercase fw-bold">
                    Location
                  </span>
                </th>
              </tr>
            </thead>

            <JobList openings={team.jobs} />

          </table>
        </div>

      </div>
    </div>
    </>
  );
}

export default Careers;
