import React, {
  useEffect,
} from 'react';

import { Row } from "reactstrap";
import { Link } from "react-router-dom";

import ReactTypingEffect from 'react-typing-effect';

const Home = () => {
  useEffect(() => {
  }, []);

  return (
    <>
    <section data-jarallax data-speed=".8" className="pt-12 pb-10 pt-md-15 pb-md-14" style={{background: 'url(/assets/img/covers/cover-19.jpg) no-repeat center center fixed', backgroundSize: 'cover'}}>
      <div className="container">
        <Row className="row align-items-center">
          <div className="col-12 col-md-4 col-lg-6">
            <h1 className="display-4 fw-bold text-white" id="welcomeHeadingSource">
              <b>Making changes</b> to your IT platforms can be scary. We can help give you confidence when it comes to&nbsp;
              <ReactTypingEffect
                 text={["Okta", "Google Workspaces", "Slack", "Identity Management", "Email"]}
                 className="text-warning"
                 speed={40}
                 eraseSpeed={50}
                 eraseDelay={1000}
                 typingDelay={500}
               />
            </h1>

            <p className="fs-lg text-white-80 mb-6">
              <b>We have seen it all.</b> We help companies and organizations, both large and small, stay secure with IT Security Architecture and Auditing services. We also specialize in infrastructure automation, custom software.
            </p>

          </div>
        </Row>
      </div>
    </section>

    <section className="py-10 py-md-14 bg-black">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">

            <h6 className="text-uppercase text-info mb-6">
              It's your company
            </h6>

            <h1 className="display-1 fw-bold text-white">
              Build security into your culture the easy way.
            </h1>

            <p className="fs-lg text-gray-500 mb-6 mb-md-8">
              Focus on the core values of your company and let us handle your security and integration needs.
            </p>

            <Link to={'/careers'} className="btn btn-info lift">
              See What's Out There <i className="fe fe-arrow-right ms-2"></i>
            </Link>

          </div>
        </div>
      </div>
    </section>

    <div className="bg-black">
      <div className="container border-top border-gray-900-50"></div>
    </div>
    </>
  )
}

export default Home;
