import React from "react";
import { Link } from "react-router-dom";

//Import Footer link
import FooterLinks from "./footer-links";

//Import Images
// import footerlogo from "../../assets/images/logo-dark.png";

const Footer = () => {
  return (
    <>
      <footer className="py-8 py-md-11 bg-black border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">

              <img src="/assets/img/logo-dark.png" width="140px" alt="..." className="footer-brand img-fluid mb-2" />

              <p className="text-gray-700 mb-2">
                It's time for change.
              </p>

              <ul className="list-unstyled list-inline list-social mb-6 mb-md-0" style={{display: 'table', margin: '0 auto', paddingRight: 60}}>
                <li className="list-inline-item list-social-item me-3">
                  <a href="https://instagram.com/angrystatic" className="text-decoration-none" target="_blank">
                    <img src="/assets/img/instagram.svg" className="list-social-icon" alt="angrystatic instagram" />
                  </a>
                </li>
                <li className="list-inline-item list-social-item me-3">
                  <a href="https://twitter.com/angrystatic" className="text-decoration-none"  target="_blank">
                    <img src="/assets/img/twitter.svg" className="list-social-icon" alt="angrystatic twitter" />
                  </a>
                </li>
              </ul>

            </div>
            {/*
            <div className="col-6 col-md-4 col-lg-2">

              <h6 className="fw-bold text-uppercase text-gray-700">
                Products
              </h6>

              <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Page Builder
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    UI Kit
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Styleguide
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Documentation
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-reset">
                    Changelog
                  </a>
                </li>
              </ul>

            </div>
            <div className="col-6 col-md-4 col-lg-2">

              <h6 className="fw-bold text-uppercase text-gray-700">
                Services
              </h6>

              <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Documentation
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Changelog
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#!" className="text-reset">
                    Pagebuilder
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-reset">
                    UI Kit
                  </a>
                </li>
              </ul>

            </div>
*/}
            <div className="col-6 col-md-4 col-lg-2">
              <h6 className="fw-bold text-uppercase text-gray-700">
                About Us
              </h6>

              <ul className="list-unstyled text-muted mb-0">
                <li className="mb-3">
                  <Link to="/about" className="text-reset">
                    About
                  </Link>
                </li>
                <li className="mb-3">
                  <Link to="/careers" className="text-reset">
                    Careers
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-6 col-md-4 col-lg-2">
              <h6 className="fw-bold text-uppercase text-gray-700">
                Legal
              </h6>

              <ul className="list-unstyled text-muted mb-0">
                <li className="mb-3">
                  <Link to="/privacy" className="text-reset">
                    Privacy Policy
                  </Link>
                </li>
                <li className="mb-3">
                  <Link to="/terms" className="text-reset">
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>

            <FooterLinks />

          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
