import Home from "./pages/Home.js";
import ClientLogin from "./pages/ClientLogin.js";
import Terms from "./pages/Terms.js";
import Privacy from "./pages/Privacy.js";

import Team from "./pages/Team.js";
import Careers from "./pages/Careers.js";
import JobPosting from  "./pages/JobPosting.js";

import Workspaces from "./pages/Services/Workspaces.js";
import Audits from "./pages/Services/Audits/Audits.js";
import AuditOkta from "./pages/Services/Audits/Okta.js";
import AuditWorkspaces from "./pages/Services/Audits/Workspaces.js";
import Formation from "./pages/Services/Formation.js";

const routes = [
  { path: "/", component: Home },
  { path: "/terms", component: Terms },
  { path: "/privacy", component: Privacy },
  { path: "/client/login", component: ClientLogin },

  { path: '/services/workspaces', component: Workspaces},
  { path: '/services/audit/:audits', component: Audits},
  { path: '/services/audit', component: Audits},
  { path: '/services/formation', component: Formation},

  { path: '/about', component: Team},
  { path: '/careers', component: Careers},
  { path: '/careers/:cat/:id', component: JobPosting},
];

export default routes;
