const jobs = [{
  name: "Engineering",
  description: "It's not about building product to solve a problem, but to innovate the next generation of IT and InfoSec tooling.",
  jobs: [
      {
        id: 1347812,
        role: "ThreatOps Specialist Lead",
        description: "Keeping our clients secure is our top priority, and your primary focus. You will be performing environment audits and providing reports on how they can be secured.",
        team: "ThreatOps",
        location: "Remote",
        whoweare: "We are a growing team of IT and Information Security practitioners who love to take technology to it's limits. The ThreatOps team goes into customer environments, similar to a Penetration Test, and explores the current state. Once security misconfigurations or the lack of best practices are found, we will draft a report and work with clients to change the configurations to keep their company safe.",
        criteria: "We want someone that is not afraid to get their hands dirty and really pay attention to detail. The purpose of this role is to lead client engagements, drive the audit and remediation process, and bring confidence to their company. Companies' hire us because we have proven we are some of the industry best.",
        requirements: [
          "Between 3 and 5 years of practical field experience in the field of Information Security",
          "Experience with Okta, Auth0, OneLogin or another Identity Provider",
          "Experience with Office365 and Okta ",
          "Willingness to learn and explore",
          "Not afraid to be wrong"
        ],
      }
    ]
},
{
  name: "Support Operations",
  description: "Supporting our creative solutions requires intuitive and instinctful problem solvers who can go 'off-script' to solve any problem.",
  jobs: [

  ]
},
{
  name: "Product",
  description: "Our product team uses our years of experience to re-envision solutions to decade old problems. We do not accept 'because this is how it's been done'",
  jobs: [
      {
        id: 312465,
        role: "HRIS Integration Product Owner ",
        description: "We are looking for someone with experience in knowing how and why HRIS systems are successful and where they fault.",
        team: "Product: HRIS",
        location: "Remote",
        whoweare: "We are a creative bunch of individuals that never accept defeat. We take great pride in our abilities to solve problems and build stable products so our clients can too. We are driven by the 'I wish this did' and the 'Why doesn't this work' mentality.",
        criteria: "We are looking for someone that knows HRIS in and out. This includes BambooHR, JustWorks, Namely, Workday and more. Every one of these solutions works great and solves problems, but they're not always speaking the same language. This leads other services to not behave correctly.",
        requirements: [
          "Between 3 and 5 years of HRIS platform tooling",
          "Experience integrating HRIS tooling with identity management platforms",
          "Willingness to learn and explore",
          "Not afraid to be wrong"
        ],
      }
    ]
}];

export default jobs;
