import React, {
  useEffect,
} from 'react';

import { Container} from "reactstrap";

const Formation = () => {
  useEffect(() => {
  }, []);

  return (
    <div  className="bg-white">
      <Container>
      <section className="pt-8 pt-md-11 pb-8 pb-md-14 ">
            <div className="container bg">
              <div className="row align-items-center">
                <div className="col-12 col-md">

                  <h1 className="display-4 mb-2">
                    Terms of Service
                  </h1>

                  <p className="fs-lg text-gray-700 mb-md-0">
                    Updated May 27<sup>th</sup>, 2021
                  </p>

                </div>
                <div className="col-auto">

                  <a href="#!" className="btn btn-primary-soft">
                    Print
                  </a>

                </div>
              </div>
              <div className="row">
                <div className="col-12">

                  <hr className="my-6 my-md-8"/>

                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-8">

                  <p className="fs-lg text-gray-800 mb-6 mb-md-8">
                    Below are our Terms of Service, which outline a lot of legal goodies, but the bottom line is it’s our aim to always take care of both you,and your company.
                  </p>

                  <h3 className="mb-5">
                    Licensing Terms
                  </h3>

                  <p className="text-gray-800">
                    By visiting and/or taking any action on Bootstrap Themes, you confirm that you are in agreement with and bound by the terms outlined below. These terms apply to the website, emails, or any other communication.
                  </p>

                  <p>
                    Here are terms of our Standard License:
                  </p>

                  <div className="d-flex">

                    <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <i className="fe fe-check"></i>
                    </span>

                    <p className="text-gray-800">
                      The Standard License grants you a non-exclusive right to make use of Theme you have purchased.
                    </p>

                  </div>
                  <div className="d-flex">

                    <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <i className="fe fe-check"></i>
                    </span>

                    <p className="text-gray-800">
                      You are licensed to use the Item to create one End Product for yourself or for one client (a “single application”), and the End Product can be distributed for Free.
                    </p>

                  </div>
                  <div className="d-flex">

                    <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <i className="fe fe-check"></i>
                    </span>

                    <p className="text-gray-800">
                      If a Theme contains code, images, or content sourced from elsewhere under a different license, that item retains its original license. The license for the code, images, or content will be noted by the Theme author. You are responsible for adhering to the original license or clearing it with the author of the code, image, or content.
                    </p>

                  </div>
                  <div className="d-flex">

                    <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <i className="fe fe-check"></i>
                    </span>

                    <p className="text-gray-800">
                      This license can be terminated if you breach it and you lose the right to distribute the End Product until the Theme has been fully removed from the End Product.
                    </p>

                  </div>
                  <div className="d-flex">

                    <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <i className="fe fe-check"></i>
                    </span>

                    <p className="text-gray-800">
                      The author of the Theme retains ownership of the Theme, but grants you the license on these terms. This license is between the author of the Theme and you. Be Colossal LLC (Bootstrap Themes) are not a party to this license or the one granting you the license.
                    </p>

                  </div>

                  <p>
                    If you opt for an Extended License:
                  </p>

                  <div className="d-flex">

                    <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <i className="fe fe-check"></i>
                    </span>

                    <p className="text-gray-800 mb-6 mb-md-8">
                      You are licensed to use the Item to create one End Product for yourself or for one client (a “single application”), and the End Product maybe sold or distributed for free.
                    </p>

                  </div>

                  <h3 className="mb-5">
                    Additional Terms
                  </h3>

                  <p className="text-gray-800">
                    By visiting and/or taking any action on Bootstrap Themes, you confirm that you are in agreement with and bound by the terms outlined below. These terms apply to the website, emails, or any other communication.
                  </p>

                  <div className="d-flex">

                    <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <i className="fe fe-check"></i>
                    </span>

                    <p className="text-gray-800">
                      All payments are processed securely through PayPal or Stripe. Bootstrap Themes does not directly process payments through the website.
                    </p>

                  </div>
                  <div className="d-flex">

                    <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <i className="fe fe-check"></i>
                    </span>

                    <p className="text-gray-800">
                      You have 14 days to evaluate your purchase. If your purchase fails to meet expectations set by the seller, or is critically flawed in some way, contact Bootstrap Themes and we will issue a full refund pending a review.
                    </p>

                  </div>
                  <div className="d-flex">

                    <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                      <i className="fe fe-check"></i>
                    </span>

                    <p className="text-gray-800 mb-6 mb-md-0">
                      Membership is a benefit for those who follow our terms and policies. We may at any time suspend or terminate your account.
                    </p>

                  </div>

                </div>
                <div className="col-12 col-md-4">

                  <div className="card shadow-light-lg">
                    <div className="card-body">

                      <h4>
                        Have a question?
                      </h4>

                      <p className="fs-sm text-gray-800 mb-5">
                        Not sure exactly what we’re looking for or just want clarification? We’d be happy to chat with you and clear things up for you. Anytime!
                      </p>

                      <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                        Email us
                      </h6>

                      <p className="fs-sm mb-0">
                        <a href="mailto:support@angrystatic.com" className="text-reset">support@angrystatic.com</a>
                      </p>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </Container>
        </div>
  )
}

export default Formation;
