import React, {
  useEffect,
} from 'react';

import ReactTypingEffect from 'react-typing-effect';
import { Link } from 'react-router-dom';
import { Container} from "reactstrap";

const Team = () => {
  useEffect(() => {
  }, []);

  return (
    <div  className="bg-white">
      <section className="py-8 py-md-11 border-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">

              <h1 className="display-2">
                A Team Built On Diversity.
              </h1>

              <p className="lead text-muted mb-7 mb-md-9">
                We believe that everyone has a unqiue story, and that is what brings value to our team that prides ourselves on being a diverse and women-lead company.
              </p>

            </div>
          </div>
          <div className="row">
            <div className="col-12">

              <div className="row gx-4">
                <div className="col-4">

                  <img className="img-fluid rounded shadow-lg" src="assets/img/photos/photo-16.jpg" alt="..." />

                </div>
                <div className="col-3">

                  <img className="img-fluid rounded shadow-lg mb-4" src="assets/img/photos/photo-17.jpg" alt="..." />

                  <img className="img-fluid rounded shadow-lg" src="assets/img/photos/photo-18.jpg" alt="..." />

                </div>
                <div className="col-5">

                  <div className="row gx-5 mb-4">
                    <div className="col-5">

                      <img className="img-fluid rounded shadow-lg" src="assets/img/photos/photo-19.jpg" alt="..." />

                    </div>
                    <div className="col-7">

                      <img className="img-fluid rounded shadow-lg" src="assets/img/photos/photo-20.jpg" alt="..." />

                    </div>
                  </div>

                  <img className="img-fluid rounded shadow-lg" src="assets/img/photos/photo-21.jpg" alt="..." />

                </div>
              </div>

            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
          <p className="lead text-muted mb-7 mb-md-9">
            Our team consists of several individuals with decades of experience in Information Technology and Cyber Security. From supporting startups to organizations of over 20,000 employees, we specialize in adapting to different environments and ever-changing business requirements.
          </p>
          </div>
          </div>
          </div>
      </section>

      <section className="pt-8 pt-md-0 border-bottom bg-light">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6">

                  <div className="ratio ratio-1x1 d-md-none" style={{background: 'center center', backgroundImage: "url('/assets/img/map.png')"}}>
                  </div>

                  <div className="position-relative h-100 vw-50 float-end d-none d-md-block">

                    <div className="shape shape-end shape-fluid-y text-light"  style={{background: 'center center', backgroundImage: "url('/assets/img/map.png')"}}>
                    </div>
                  </div>

                </div>
                <div className="col-12 col-md-6 col-lg-5 offset-lg-1 py-8 py-md-11 py-lg-12">

                  <h2>
                    Built in Los Angeles.<br />
                    <span className="text-success">But work&nbsp;</span>
                    <ReactTypingEffect
                       text={["anywhere.", "everywhere."]}
                       className="text-success"
                       speed={40}
                       eraseSpeed={50}
                       eraseDelay={1000}
                       typingDelay={500}
                     />
                  </h2>

                  <p className="fs-lg text-gray-700 mb-6">
                    We built angry<b>static</b> in Los Angeles. With it's easy access to the world with LAX and tons of specialized talent, we would not be able to thrive without LA.
                  </p>

                  <div className="d-flex">
                    <div className="pe-5">
                      <h3 className="text-success mb-0">
                        <span data-countup="'startVal': 0}" data-to="100" data-aos="" data-aos-id="countup:in" className="aos-init aos-animate">100</span>%
                      </h3>
                      <p className="text-gray-700 mb-0">
                        Animal Lovers
                      </p>
                    </div>
                    <div className="border-start border-gray-300"></div>
                    <div className="px-5">
                      <h3 className="text-success mb-0">
                        <span data-countup="{&quot;startVal&quot;: 0}" data-to="85" data-aos="" data-aos-id="countup:in" className="aos-init aos-animate">360</span> days
                      </h3>
                      <p className="text-gray-700 mb-0">
                         of Beautiful Weather
                      </p>
                    </div>
                    <div className="border-start border-gray-300"></div>
                    <div className="ps-5">
                      <h3 className="text-success mb-0">
                        <span data-countup="{&quot;startVal&quot;: 0}" data-to="52" data-aos="" data-aos-id="countup:in" className="aos-init aos-animate">50</span>/<span data-countup="{&quot;startVal&quot;: 0}" data-to="48" data-aos="" data-aos-id="countup:in" className="aos-init aos-animate">50</span>
                      </h3>
                      <p className="text-gray-700 mb-0">
                        Men to Women
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section className="pt-6 pt-md-8">
            <div className="container pb-6 pb-md-8">
              <div className="row align-items-center">
                <div className="col-12 col-md">

                  <h3 className="fw-bold mb-1">
                    Interested in joining our team?
                  </h3>

                  <p className="fs-lg text-muted mb-5 mb-md-0">
                    Check out some of our openings!
                  </p>

                </div>
                <div className="col-12 col-md-auto">

                <Link className="btn btn-primary lift" to={'/careers'}>
                  Apply now
                </Link>

                </div>
              </div>
            </div>
          </section>
      </div>
  )
}

export default Team;
