import React from 'react';
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";

import CookieConsent from "react-cookie-consent";

import Footer from './components/Footer/footer.js';
import Navbar from './components/Navbar/Navbar.js';

import NotFound from './pages/NotFound.js';

import routes from './routes.js';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-KM1BLB05XG'
}

TagManager.initialize(tagManagerArgs)

const App = () => {

  return (
    <>
      <Navbar />

      <Switch>
        {routes.map((route, idx) => (
          <Route path={route.path} exact component={route.component} key={idx} />
        ))}
        <Route component={NotFound} />
      </Switch>

      <Footer />

      <CookieConsent
        location="bottom"
        buttonText="Continue"
        cookieName="ags-cc-store1"
        style={{ background: "rgb(32 40 64)" }}
        buttonStyle={{ background: "#335eea", color: "#ffffff", fontSize: "14px", borderRadius: '8px' }}
        expires={7}>
        <b>We use cookies to enhance your experience.{" "}</b>
        <span style={{ fontSize: "12px" }}>For the record, we don't sell any of your data.</span>
      </CookieConsent>
    </>
  );
}

export default withRouter(App);
