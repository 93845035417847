import React, {
  useEffect,
  useState,
  Component,
} from 'react';

import { Link } from 'react-router-dom';
import { Container } from "reactstrap";

const NotFound = () => {
  useEffect(() => {
  }, []);

  return (
    <>
    <Container>
      <div>
        <h1>404 - Not Found!</h1>
        <Link to={'/'}>
          Go Home
        </Link>
      </div>
    </Container>
    </>
  )
}

export default () => (
  <NotFound />
)
