import React, {
  useEffect,
  useState,
} from 'react';

import { Button } from "reactstrap";
import { Redirect, Link } from "react-router-dom";

import jobs from '../jobs.js';

const ReferFriend = () => {
  const [message, setMessage] = useState('Refer a friend');

  const copy = () => {
    setMessage('Copied!')
    navigator.clipboard.writeText(window.location.toString())
  }

  return (
    <Button className="btn btn-primary-soft me-1" onClick={copy}>
      {message}
    </Button>
  )
}

const JobPosting = (props) => {

  const [pageTitle, setPageTitle] = useState('Loading...');
  const [jobPosting, setJobPosting] = useState({
    name: "Loading...",
    description: "",
    id: 0,
    whoweare: "",
    criteria: "",
    requirements: [],
  });

  useEffect(() => {
    let postings = jobs[props.match.params.cat];
    let posting = postings.jobs.filter(obj => {
      if ( obj.id == props.match.params.id ) {
        return obj
      }
    });

    setPageTitle(posting[0].role);
    setJobPosting(posting[0]);

    <Redirect to="/careers" />
  }, []);

  return (
    <section className="pt-8 pt-md-11">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md">


            <Link to={"/careers"} className="fw-bold fs-sm text-decoration-none mb-3">
              <i className="fe fe-arrow-start me-3"></i> All listings
            </Link>


            <h1 className="display-4 mb-2">
              <span>{jobPosting.role}</span>
              <span className="text-muted" style={{ display:'block', fontSize: '12px', paddingLeft: 10, lineHeight:'8px'}}>JobID #{props.match.params.id}</span>
            </h1>


            <p className="fs-lg text-gray-700 mb-5 mb-md-0">
              {jobPosting.team} · {jobPosting.location} · Full time
            </p>

          </div>
          <div className="col-auto">

            <ReferFriend />
          </div>

        </div>
        <div className="row">
          <div className="col-12">


            <hr className="my-6 my-md-8 border-gray-300" />

          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8">


            <h3>
              Who we are
            </h3>


            <p className="text-gray-800 mb-6 mb-md-8">
              {jobPosting.whoweare}
            </p>


            <h3>
              What we’re looking for
            </h3>

            <p className="text-gray-800 mb-5">
              {jobPosting.criteria}
            </p>

            <h3 className="mb-5">
              Applicant requirements
            </h3>

            {jobPosting.requirements.map((r, idx) => (
              <div className="d-flex" key={idx}>
                <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check"></i>
                </div>

                <p className="text-gray-800">
                  {r}
                </p>
              </div>
            ))}

          </div>
          <div className="col-12 col-md-4">


            <div className="card shadow-light-lg mb-5">
              <div className="card-body">


                <h4>
                  Need help?
                </h4>


                <p className="fs-sm text-gray-800 mb-5">
                  Not sure exactly what we’re looking for or just want clarification? We’d be happy to chat with you and clear things up for you. Anytime!
                </p>

                <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                  Email us
                </h6>


                <p className="fs-sm mb-0">
                  <code className="text-reset">careers [at] angrystatic [dot] com</code>
                </p>

              </div>
            </div>


            <div className="card shadow-light-lg">
              <div className="card-body">


                <h4>
                  Don't see a job for you?
                </h4>


                <p className="fs-sm text-gray-800">
                  Do you feel like you belong working with Good Themes, but we just don’t have your dream job posted? No problem, just reach out.
                </p>


                <Link to={"/careers"} className="fw-bold fs-sm text-decoration-none">
                  Let us know <i className="fe fe-arrow-right ms-3"></i>
                </Link>

              </div>
            </div>

          </div>
        </div>
      </div>
      <div id="apply" />
    </section>
  )
}

export default JobPosting;
