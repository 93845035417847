import React, {
  useEffect,
} from 'react';

import { Container, FormGroup, Form, Label, Input } from "reactstrap";

const ClientLogin = () => {
  useEffect(() => {
  }, []);

  return (
      <div className="bg-white">
      <section className="section-border border-primary">
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center gx-0s">
          <div className="col-12 col-md-5 col-lg-4 py-md-11">

            <h1 className="mb-0 fw-bold text-center">
              Client Login
            </h1>

            <p className="mb-6 text-center text-muted">
              Access your client profile and start making a difference.
            </p>

            <Form className="mb-6" action="/login" method="POST">

              <FormGroup className="Form-group">
                <Label className="Form-Label" for="email">
                  Email Address
                </Label>
                <Input type="email" className="Form-control" id="email" placeholder="Enter your email" />
              </FormGroup>

              <FormGroup className="Form-group mb-5">
                <Label className="Form-Label" for="password">
                  Password
                </Label>
                <Input type="password" className="Form-control" id="password" placeholder="Enter your password" />
              </FormGroup>

              <button className="btn w-100 btn-primary" type="submit" disabled={'disabled'}>
                Sign in
              </button>

            </Form>

          </div>
        </div>
      </div>
    </section>
      </div>
  )
}

export default ClientLogin;
