import React, { useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler,
} from "reactstrap";

import { useLocation, Link } from 'react-router-dom';

//Import Stickey Header
// look into https://blog.logrocket.com/using-react-hooks-to-create-sticky-headers/

const HoverControlledDropdown = (props) => {
  const { isParentToggled, ...dropdownProps } = props;
  const [isOpen, updateIsOpen] = useState(false);

  let toggleProps = {};
  if (props.isParentToggled) {
    toggleProps = {
      onMouseOver: () => updateIsOpen(true),
      onFocus: () => updateIsOpen(true),
      onMouseLeave: () => updateIsOpen(false),
      onBlur: () => updateIsOpen(false),
      toggle: () => updateIsOpen(!isOpen),
      isOpen: isOpen,
    }
  }

  let p = {
    ...dropdownProps,
    ...toggleProps
  };

  return (
    <Dropdown
      {...p}
    />
  );
};

const NavbarPage = () => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  const location = useLocation()
  React.useEffect(() => {
    setCollapsed(true);
  }, [location])

  return (
    <>
      <Navbar className="navbar navbar-expand-lg navbar-light bg-white">
        <Container className="container-fluid">

          <Link className="navbar-brand" to="/">
            <img src="/assets/img/logo-light.png" className="navbar-brand-img" alt="angrystatic" />
          </Link>

          <NavbarToggler onClick={toggleNavbar} className="mr-2 navbar-toggler">
            <span className="navbar-toggler-icon"></span>
          </NavbarToggler>

          <Collapse isOpen={!collapsed} className="collapse navbar-collapse" id="navbarCollapse" navbar>
            <Nav navbar className="navbar-nav ms-auto">

              {!collapsed && (
                <Button onClick={toggleNavbar} className="navbar-btn btn btn-sm btn-primary lift ms-auto">
                Close
                </Button>
              )}

              <NavItem>
                <NavLink href="/">Home</NavLink>
              </NavItem>
              <HoverControlledDropdown nav
                isParentToggled={collapsed}
                className="nav-item dropdown" id="navbarLandings">
                <DropdownToggle nav caret className="nav-link dropdown-toggle">
                  Services
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>IT Services</DropdownItem>
                  <DropdownItem>
                      <Link className="dropdown-item mb-5" to={"/services/workspaces"}>
                        Get Google Workspaces
                      </Link>
                  </DropdownItem>
                  <DropdownItem divider/>

                  <DropdownItem header>Security Auditing</DropdownItem>
                  <DropdownItem>
                    <Link to={"/services/audit/workspace"}>
                        Google Workspace
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                      <Link className="dropdown-item mb-5" to={"/services/audit/okta"}>
                        Okta
                      </Link>
                  </DropdownItem>
                  <DropdownItem divider/>

                  <DropdownItem header>New Businesss</DropdownItem>
                  <DropdownItem>
                      <Link to={"/services/formation"}>
                        Formation
                      </Link>
                  </DropdownItem>
                </DropdownMenu>
              </HoverControlledDropdown>
              <HoverControlledDropdown nav
                isParentToggled={collapsed}
                className="nav-item dropdown" id="navbarLandings">
                <DropdownToggle nav caret className="nav-link dropdown-toggle">
                  About Us
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>About Us</DropdownItem>
                  <DropdownItem>
                      <Link to={"/about"}>
                        About Us
                      </Link>
                  </DropdownItem>
                  <DropdownItem>
                      <Link to={"/careers"}>
                        Careers
                      </Link>
                  </DropdownItem>
                </DropdownMenu>
              </HoverControlledDropdown>
            </Nav>

            <Link className="navbar-btn btn btn-sm btn-primary lift ms-auto" to={"/client/login"}>
              <span>Client Login</span>
            </Link>

          </Collapse>

        </Container>
      </Navbar>
    </>
  );
}


export default NavbarPage;
