import React, {
  useEffect,
} from 'react';

import { Container} from "reactstrap";

const Privacy = () => {

  return (
    <div  className="bg-white">
      <Container>
      <section className="pt-8 pt-md-11 pb-8 pb-md-14 ">
            <div className="container bg">
              <div className="row align-items-center">
                <div className="col-12 col-md">

                  <h1 className="display-4 mb-2">
                    Privacy Policy
                  </h1>

                  <p className="fs-lg text-gray-700 mb-md-0">
                    Updated Agust 20<sup>th</sup>, 2022
                  </p>

                </div>
                <div className="col-auto">

                  <a href="#!" className="btn btn-primary-soft">
                    Print
                  </a>

                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <hr className="my-6 my-md-8"/>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-8">

                  <p className="fs-lg text-gray-800 mb-6 mb-md-8">
                    Below is our privacy policy in plain english, and without crazy and difficult to understand legalease.
                  </p>

                  <h3 className="mb-5">
                    Your Data is Your Data
                  </h3>

                  <p className="text-gray-800">
                    By visiting this website, we will record your search terms, IP address, geo-location and brower for device footprint using Google Analytics so we can optimize your browsing experience. We do not collect any user data or personal information in non-aggregate form. As a result, we do not store any information about you or your visit on our servers, nor do we store meaningful information elsewhere knowlingly. We are a security company and take your privacy seriously.
                  </p>

                  <p>
                    Here are terms of our Standard License:
                  </p>

                  <div className="">

                    <h3 className="bg-success-soft mt-1 me-4">
                      Cookies
                    </h3>

                    <p className="text-gray-800">
                      Google Analtics records 3 cookies for performance and return tracking of your browser. There is no sensitive information in these cookies. These are required and if you would not like to have these track you, please use a tracking blocking extension.
                    </p>

                    <p className="text-gray-800">
                      Our Cookie compliance cookie will store your cookie opt-in/opt-out settings depending on your geo-location to be compliant with international law. You may or may not have this cookie as a result.
                    </p>

                  </div>
                </div>
                <div className="col-12 col-md-4">

                  <div className="card shadow-light-lg">
                    <div className="card-body">

                      <h4>
                        Have a question?
                      </h4>

                      <p className="fs-sm text-gray-800 mb-5">
                        We have a privacy specific alias where we will receive and review compliance related privacy requests. Reach out to us if you have any questions.
                      </p>

                      <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                        Email us
                      </h6>

                      <p className="fs-sm mb-0">
                        <a href="mailto:privacy@angrystatic.com" className="text-reset">privacy@angrystatic.com</a>
                      </p>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </Container>
        </div>
  )
}

export default Privacy;
